/*
 * Search Utils
 */
var rb = rb || {};
var MantleSearch = (function(MantleSearch) {

  var arrayIncludes = function(arr, search) {
    return !!~arr.indexOf(search);
  }

  // For now this is hardcoded to an endeca transtion
  MantleSearch.getSuppresionList = function() {
    if (!rb || !rb.endeca || !rb.endeca.product_suppression_list) {
      return false;
    }

    var suppressionList = rb.endeca.product_suppression_list;
    var productList = suppressionList.split(',').map(function(item) {
      return item.trim();
    });
    return productList;
  };

  /*
   * Filter a productList via suppression conditions.
   *
   * Initially this only works via an explicit Product ID list.
   */
  MantleSearch.filterProductList = function(productList) {
    var filteredProductList = [];
    var suppressionList = this.getSuppresionList();
    // If no suppression list. Just return original.
    if (!suppressionList || suppressionList.length === 0) {
      return productList;
    }

    for (var i = 0; i < productList.length; i++) {
      var item = productList[i];
      var productId = item.PRODUCT_ID;

      // Suppress by product id. In the future we could have other ways of
      // suppression.
      if (arrayIncludes(suppressionList, productId)) {
        continue;
      }

      filteredProductList.push(item);
    }
    return filteredProductList;
  };

  return MantleSearch;
})(MantleSearch || {});
