var MantleCarousel = (function(MantleCarousel, $) {

  /*
   * Largely a wrapper around $.slick with some additional processing and event
   * handlers added first.
   */
  MantleCarousel.initializeCarousel = function(carousel, settings) {
    var $carousel = $(carousel);

    var saved_options = $carousel.data('mantle-saved-options');
    $carousel.removeData('mantle-saved-options');
    $carousel.removeAttr('data-mantle-saved-options');

    if (saved_options) {
      _.extend(settings, saved_options);
    }

    // backwards compat with old slick carousel.
    settings['onBeforeChange'] = function(slick, currentIndex, targetIndex) {
      $carousel.trigger('beforeChange', [slick, currentIndex, targetIndex]);
    };
    settings['onAfterChange'] = function(slick, currentIndex) {
      $carousel.trigger('afterChange', [slick, currentIndex]);
    };
    // apply video fix
    var slide_selector = settings['slide'];
    _mantle_media_fix_video_carousel($carousel, slide_selector);
    MantleCarousel.attach_slide_attributes($carousel, slide_selector);

    // attach slide direciton class
    $carousel.on('beforeChange', function(event, slick, currentSlide, targetSlide) {
      MantleCarousel.add_slide_direction_class($carousel, event, slick, currentSlide, targetSlide);
      MantleCarousel.set_carousel_active_classes($carousel, targetSlide, slick);
    });

    $carousel.slick(settings);
    MantleCarousel.set_carousel_active_classes($carousel);
    $carousel.trigger('resize');
  };

  /*
   * Wrapper around slickAdd that maintains the mantle-carousel-id.
   */
  MantleCarousel.addSlide = function($carousel, slide) {
    var currentId = $carousel.data('mantle-carousel-last-id');
    var nextId = currentId + 1;
    var $slide = $(slide);
    $slide.attr('data-mantle-carousel-id', nextId);
    $carousel.data('mantle-carousel-last-id', nextId);

    MantleCarousel.slick_method($carousel, 'slickAdd', $slide);
  };

  /*
   * Remove slide with special python-like indexing.
   *
   * TODO: Might add flag to handle https://github.com/kenwheeler/slick/issues/3066
   */
  MantleCarousel.removeSlide = function($carousel, slideIndex) {
    // Special indexing to grab last value.
    // Note that passing false removes last slide. This was found by looking at
    // code. The web docs are wrong.
    if (slideIndex === -1) {
      slideIndex = false;
    }
    MantleCarousel.slick_method($carousel, 'slickRemove', slideIndex);
  };

  /*
   * Quick util to get slideCount.
   */
  MantleCarousel.slideCount = function($carousel) {
    var slick = MantleCarousel.slick_method($carousel, 'getSlick');
    return slick.slideCount;
  };

  MantleCarousel.currentSlide = function(carousel) {
    var $carousel = $(carousel);
    var currentSlide = MantleCarousel.slick_method($carousel, 'slickCurrentSlide');
    return currentSlide;
  }

  MantleCarousel.slideTo = function(carousel, slide_number, skip_animation) {
    var $carousel = $(carousel);
    if (!this.isCarousel($carousel)) {
      // shoudl this implicitly set the initialSlide as a saved option?
      return;
    }
    MantleCarousel.slick_method($carousel, 'slickGoTo', slide_number, skip_animation);
  }

  MantleCarousel.get_mantle_id = function($carousel, $slide) {
    var mantle_id = $slide.data('mantle-carousel-id');
    return mantle_id;
  }

  MantleCarousel.get_slides_by_mantle_id = function($carousel, mantle_id) {
    var $slides = $('.slick-slide[data-mantle-carousel-id="' + mantle_id + '"]', $carousel);
    return $slides;
  };

  /*
   * get Slide elements by their index. This is not the mantle-carousel-id. but
   * the ordinal # of the slide and can change via addSlide/removeSlide calls
   *
   * This will return a pack and not a jquery element.
   * slide: the real actual target slide
   * all: the slide including clones
   *
   */
  MantleCarousel.get_slidepack_by_index = function($carousel, index, slick) {
    var pack = {};
    if (_.isUndefined(slick)) {
      slick = MantleCarousel.slick_method($carousel, 'getSlick');
    }
    var $slides = slick.$slides;
    $slide = $slides.eq(index);

    pack['slide'] = $slide;
    var mantle_id = this.get_mantle_id($carousel, $slide);
    var $slides = this.get_slides_by_mantle_id($carousel, mantle_id);
    pack['allSlides'] = $slides;
    return pack;
  };

  /*
   * Is the dom element currently a carousel?
   */
  MantleCarousel.isCarousel = function(carousel) {
    var $carousel = $(carousel);
    if ($carousel.hasClass('slick-initialized')) {
      return true;
    }
    return false;
  }

  MantleCarousel.attach_slide_attributes = function($carousel, slide_selector) {
    // attach a unique per slide data attribute
    // this is not meant to be an ordinal index. even though in practice it is.
    if (!slide_selector) {
      slide_selector = 'div';
    }
    var $slides = $carousel.find('> ' + slide_selector);
    $slides.each(function(i, obj) {
      var $slide = $(obj);
      $slide.attr('data-mantle-carousel-id', i);
    });

    // Save the last ID. Need this to serially assign ids.
    $carousel.data('mantle-carousel-last-id', $slides.length - 1);
  }

  /*
   * Add a sliding-left / sliding-right class to help with advanced peeking
   * animations.
   */
  MantleCarousel.add_slide_direction_class = function($carousel, event, slick, currentSlide, targetSlide) {
    direction = 'right';

    var endSlide = slick.slideCount - 1;

    if ( targetSlide < currentSlide ) {
      direction = 'left';
    }

    // wrap around left
    if (currentSlide == 0 && targetSlide == endSlide) {
      direction = 'left';
    }

    // wrap around right
    if (currentSlide == endSlide && targetSlide == 0) {
      direction = 'right';
    }

    if( direction == 'left' ) {
      $carousel.addClass('sliding-left');
    } else {
      $carousel.removeClass('sliding-left');
    }
  };

  MantleCarousel.slick_method = function() {
    var args = Array.prototype.slice.call(arguments);
    var $carousel = args[0];
    var method_name = args[1];
    if (typeof $carousel[method_name] === "function") {
      return $carousel[method_name].apply($carousel, args.slice(2));
    } else {
      return $carousel.slick.apply($carousel, args.slice(1));
    }
  }

  /*
   * set advanced active / centered
   *
   * Right now this is hardcoded to 2f 2 item centered carousel.
   *
   * Eventually, I want this to be automatica based on settings detection and
   * hitbox detection. For something like a variableWidth grid, the ability to
   * detect which items are in the carousel view.
   *
   * Granted the targetSlide stuff will need to be detected since I previously
   * called this in beforeChange event callback. That was doable because while
   * I am using variableWidth, the widths are still in multiples of columns. In
   * a *real* variableWidth, we'd need to do pixel level calcs.
   */
  MantleCarousel.set_carousel_active_classes = function($carousel, targetSlide, slick) {
    if (_.isUndefined(slick)) {
      slick = MantleCarousel.slick_method($carousel, 'getSlick');
    }

    if (_.isUndefined(targetSlide)) {
      targetSlide = MantleCarousel.slick_method($carousel, 'slickCurrentSlide');
    }

    // This carousel has no slides.
    if (!slick || slick.slideCount === 0) {
      return;
    }

    var centerMode = slick.options['centerMode'];
    var variableWidth = slick.options['variableWidth'];

    // unset the slides
    $('.slick-slide', $carousel).removeClass('active-main');
    $('.slick-slide', $carousel).removeClass('active-left');
    $('.slick-slide', $carousel).removeClass('active-right');
    $('.slick-slide', $carousel).removeClass('overflow-left');
    $('.slick-slide', $carousel).removeClass('overflow-right');

    var targetPack = MantleCarousel.get_slidepack_by_index($carousel, targetSlide, slick);
    var $targetSlide = targetPack.slide;
    // also target the clones
    if (targetPack.allSlides) {
      targetPack.allSlides.addClass('active-main');
    }

    // start of hitbox / pixel detection true variableWidth detection.
    var hitbox = MantleHitbox.generate_hitbox($carousel);
    var slide_hitbox = MantleHitbox.generate_hitbox($targetSlide);

    var next_id = targetSlide;

    // this is the 2f based grid-colspan space checking.
    var grid_colspan = $targetSlide.data('grid-colspan') || 1;
    if (grid_colspan < 2) {
      next_id = targetSlide + 1;
      if (next_id >= slick.slideCount) {
        next_id = 0;
      }

      targetPack.allSlides.addClass('active-left');
      var nextPack = MantleCarousel.get_slidepack_by_index($carousel, next_id, slick);
      if (nextPack.allSlides) {
        nextPack.allSlides.addClass('active-right');
      }
    }

    var overflow_right = next_id + 1;
    if (overflow_right >= slick.slideCount) {
      overflow_right = 0;
    }
    var overflow_right_pack = MantleCarousel.get_slidepack_by_index($carousel, overflow_right, slick);
    overflow_right_pack.allSlides.addClass('overflow-right');

    var overflow_left = targetSlide - 1;
    if (overflow_left < 0) {
      overflow_left = slick.slideCount - 1;
    }
    var overflow_left_pack = MantleCarousel.get_slidepack_by_index($carousel, overflow_left, slick);
    overflow_left_pack.allSlides.addClass('overflow-left');
  };

  /*
   * Note that this is like slick("slickSetOption") except that it will check
   * if there is any change in settings. If no, it will do nothing. This is to
   * prevent ui refreshes when they are not necessary.
   */
  MantleCarousel.setOptions = function($carousel, options, refresh_ui) {
    // if this carousel has not initialized. save the options for when the init
    // happens.
    if (!this.isCarousel($carousel)) {
      $carousel.data('mantle-saved-options', options);
      return;
    }
    var modified = false;
    _.each(options, function(value, key) {
      var old_option = MantleCarousel.slick_method($carousel, 'slickGetOption', key);
      if (old_option !== value) {
        modified = true;
        return;
      }
    });
    if (modified) {
      var slick = MantleCarousel.slick_method($carousel, 'getSlick');
      // note that slickSetOption only changes the existing settings.
      // every breakpoint rebuilds the options object from the originalSettings
      // array.
      slick.originalSettings = _.extend(slick.originalSettings, options);
      if (refresh_ui) {
        // Trigger a unload, reinit
        // previously we called unload/reinit directly but this clashes with
        // $.fn.unload for older method check in slick_method. It was calling
        // $slider.unload() method instead of $slider.slick('unloadl')
        MantleCarousel.slick_method($carousel, 'slickSetOption', '__mantle', true, true);
      }
    }
  };

  return MantleCarousel;
})(MantleCarousel || {}, jQuery);
