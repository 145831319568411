var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var MantleInstantOn = MantleInstantOn || {};
MantleInstantOn.SPP = MantleInstantOn.SPP || {};
var MantlePerf = MantlePerf || {};

(function($) {
  $(function() {
  function getInternetExplorerVersion() {
    var rv = -1;
    var ua, re;
    if (navigator.appName === 'Microsoft Internet Explorer') {
      ua = navigator.userAgent;
      re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
      if (re.exec(ua) != null) {
        rv = parseFloat(RegExp.$1);
      }
    } else if (navigator.appName === 'Netscape') {
      ua = navigator.userAgent;
      re = new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})');
      if (re.exec(ua) != null) {
        rv = parseFloat(RegExp.$1);
      }
    }
    return rv;
  }
  /*
   * This is called inline to very quickly render mpp grids. The rest of the
   * grid items will be handled by default lazy loading like behavior
   */
  prodcat.ui.renderMPPGrid = function() {
    // Can't do this without autoplacement.
    if (getInternetExplorerVersion() === 11) {
      return;
    }
    var $mpp_layouts = $('.js-mpp-container');
    $mpp_layouts.each(function(i, mpp) {
      var $grids = $('.js-product-grid', mpp);
      $grids.each(function(i, grid) {
        var $grid = $(grid);
        var skipLazyloadCount = $grid.data('skip-lazyload-count') || 0;
        if (!skipLazyloadCount) {
          return;
        }
        $('.js-grid-item', $grid).each(function(i, obj) {
          var $item = $(obj);
          if (i >= skipLazyloadCount) {
            return false;
          }

          // Trigger lazyload for inline content items.
          if ($item.data('inline-content')) {
            var $lazyloadElements = $item.find('.lazyload');
            $lazyloadElements.each(function(i, obj) {
              lazySizes.loader.unveil(obj);
            });
          }

          // Add the proper grid delay attribute and trigger the load
          $item.attr('data-grid-delay', i);
          $item.addClass('mg-item-reveal');
        });
      });
    });
  };

  MantleInstantOn._renderSPPImages = function(skuData, $sppImagesContainer) {
    var largeImagePack = prodcat.ui.generateLargeImagePackWithWidth(skuData, null);
    var largeImages = largeImagePack.images;

    var $thumbs = $('.js-spp-carousel__thumbnail', $sppImagesContainer);
    var $thumbnailContainer = $('.js-product-full__image-thumbnails', $sppImagesContainer);

    var $fullImages = $('.js-spp-carousel__slide', $sppImagesContainer);
    $fullImages.each(function(i, obj) {
      if (i >= largeImages.length) {
        return false;
      }
      var $fullImage = $(obj);
      var $fullImageImage = $('img', $fullImage);

      $fullImageImage.attr('data-src', largeImages[i]);
      $fullImageImage.addClass('instant-on-processed');

      if (i === 0) {
        $fullImageImage.attr('src', largeImages[i]);
        $fullImage.addClass('initial-current-slide');
      } else {
        $fullImageImage.addClass('lazyload');
      }
    });

    // how many thumb to load without lazy load.
    // NOTE: we hide thumbnails on mobile. So don't try to load them
    if ($thumbnailContainer.css('display') !== 'none') {
      var thumbsLoadImmediate = 4;
      var thumbnailImagePack = prodcat.ui.generateLargeImagePackWithWidth(skuData, 150);
      var thumbnailImages = largeImages;
      if (thumbnailImagePack && thumbnailImagePack.images) {
        thumbnailImages = thumbnailImagePack.images;
      }

      $thumbs.each(function(i, obj) {
        if (i >= thumbnailImages.length) {
          return false;
        }
        var $thumb = $(obj);
        var $thumbImage = $('img', $thumb);
        var thumbnailImageSrc = thumbnailImages[i];

        $thumbImage.addClass('instant-on-processed');
        $thumbImage.attr('data-src', thumbnailImageSrc);

        if (i < thumbsLoadImmediate) {
          $thumbImage.attr('src', thumbnailImageSrc);
        } else {
          $thumbImage.addClass('lazyload');
        }
      });
    }

    // Set what the current images is
    $sppImagesContainer.attr('data-sku-base-id', skuData.SKU_BASE_ID);
  };

  MantleInstantOn.renderSPPImages = function(cssId) {
    if (!cssId) {
      return;
    }

    var $sppImagesContainer = $('#' + cssId);
    var skuBaseId = this.SPP.skuBaseId;

    var skuData = prodcat.data.getSku(skuBaseId);

    if (!skuData) {
      return;
    }

    MantleInstantOn._renderSPPImages(skuData, $sppImagesContainer);
  };

  MantleInstantOn._renderSPP = function(cssId) {
    if (!cssId) {
      return;
    }

    var $sppContent = $('#' + cssId);

    if ($sppContent.length !== 1) {
      return;
    }

    var $fullContent = $('.product-full', $sppContent);
    var productId = $fullContent.data('product-id');
    if (!productId) {
      return;
    }

    // This is set in preRenderSPP
    var skuBaseId = this.SPP.skuBaseId;

    // Set the sku select value to the router value.
    var $skuSelect = $('.js-sku-select-shades-select', $fullContent);
    $skuSelect.val(skuBaseId);

    // Initialial select box early
    var $selects = $('.selectBox:not(.selectBox-attached)', $fullContent);
    window.requestAnimationFrame(function() {
      $selects.each(function(i, obj) {
        Drupal.behaviors.selectBox.attachReal(obj);
      });
    })
  };

  /*
   * Runs Before renderSPP. Largely for setting up page_data and parsing the
   * shade router.
   *
   * Could be moved up as high as in head. Just need to know the product id of
   * the SPP that we are on.
   */
  MantleInstantOn.preRenderSPP = function(cssId) {
    if (!cssId) {
      return;
    }

    var $sppContent = $('#' + cssId);
    // We don't know what to with multiple spp layouts.
    if ($sppContent.length > 1) {
      return;
    }

    var productId = $sppContent.data('product-id');

    if (!productId) {
      return;
    }

    this.setupSPPData(productId);
  };

  MantleInstantOn.setupSPPData = function(productId) {
    prodcat.data.updatePageData(this.page_data);

    var prodData = prodcat.data.getProduct(productId);

    if (!prodData) {
      return;
    }

    var routerSku = prodcat.ui.getSkuFromUrl(prodData);
    var skuBaseId = prodData.defaultSku.SKU_BASE_ID;
    if (routerSku) {
      skuBaseId = routerSku;
    }
    this.SPP.skuBaseId = skuBaseId;
  };

  MantleInstantOn.renderSPP = function(cssId) {
    MantlePerf.thresholdLog(function() {
      MantleInstantOn._renderSPP(cssId);
    }, 'renderSPP');
  };
})})(jQuery);
