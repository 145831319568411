var JSBoot = JSBoot || {};
var MantleGrid = MantleGrid || {};
var MantleCarousel = MantleCarousel || {};

/*
 * Helper func to fix stuck lazyloadgin in cloned slides.
 *
 * TODO: officially move this into mantle_carousel
 */

function _mantle_carousel_check_for_lazy_errors($slides) {
  /*
   * If we were lazy loading. Remove the class since src was already set.
   */
  $slides.find('.lazyloading').each(function(i, obj) {
    // restart the unveil. Due to unveil hook plugins, this might not be a
    // simple img src
    /* eslint-disable-next-line no-undef */
    lazySizes.loader.unveil(obj);
  });

  /*
   * Sometimes a clone outside of a viewport won't lazyload. This might only
   * apply to custom lazyloading? I know I hit it for video.
   */
  $slides.find('.lazyload').each(function(i, obj) {
    if (!$(obj).is(':visible')) {
      return;
    }
    /* eslint-disable-next-line no-undef */
    lazySizes.loader.unveil(obj);
  });
}

/*
 * This will attempt to fix the lazy slides for every visible slide and any
 * of its clones.
 *
 * We will attempt to fix the two edge slides as well.
 */
// eslint-disable-next-line no-unused-vars
function _mantle_carousel_check_range($carousel, currentSlide, slidesToShowCount) {
  var $slideItems = $carousel.find('.js-product-grid-item:not(.slick-cloned)');
  var totalCols = MantleGrid.gather_total_cols($slideItems);
  var $slides = null;

  var totalToFix = slidesToShowCount + 2; // get the visible plus left/right edge

  // fix every slide
  if (totalCols <= totalToFix) {
    $slides = $carousel.find('.slick-slide');
    _mantle_carousel_check_for_lazy_errors($slides);
    return;
  }

  var leftEdge = currentSlide - 1;
  if (leftEdge < 0) {
    leftEdge = totalCols - 1;
  }

  var current_index = leftEdge;
  for (var i = 0; i < totalToFix; i++) {
    if (current_index >= totalCols) {
      current_index = 0;
    }
    $slides = MantleCarousel.get_slides_by_mantle_id($carousel, current_index);
    _mantle_carousel_check_for_lazy_errors($slides);
  }
}

var MantleWindow = (function(MantleWindow, $) {
  MantleWindow.onResize = function(callback, namespace, interval) {
    var event = 'resize';
    if (namespace) {
      event = event + '.' + namespace;
    }

    if (!interval) {
      interval = 50;
    }

    var debounced = _.debounce(callback, interval);

    $(window).on(event, debounced);
  };

  /*
   * resize event handler that only calls callabck when the width of client
   * changes. This is largely for ios where scrolling will change client height
   * due to utility bar.
   */
  MantleWindow.onResizeWidth = function(callback, namespace, interval) {
    var event = 'resize';
    if (namespace) {
      event = event + '.' + namespace;
    }

    if (!interval) {
      interval = 50;
    }

    var oldWidth = $(window).width();
    var widthCheck = function() {
      var newWidth = $(window).width();
      if (newWidth !== oldWidth) {
        oldWidth = newWidth;
        callback();
      }
    };
    $(window).on(event, _.debounce(widthCheck, interval));
  };

  MantleWindow.onScroll = function(callback, namespace, interval) {
    var event = 'scroll';
    if (namespace) {
      event = event + '.' + namespace;
    }

    if (!interval) {
      interval = 50;
    }

    var debounced = _.debounce(callback, interval);

    $(window).on(event, debounced);
  };

  MantleWindow.removeEvents = function(namespace) {
    $(window).off('.' + namespace);
  };

  return MantleWindow;
})(MantleWindow || {}, jQuery);
