(function($) {
  'use strict';
  Drupal.behaviors.selectBox = {
    observer: null,
    setupObserver: function() {
      var self = this;

      if (self.observer !== null) {
        return;
      }
      var handler = function(entries) {
        _.each(entries, function(entry) {
          if (!entry.isIntersecting) {
            return;
          }

          var elem = entry.target;
          // no longer wait for onscreen
          self.observer.unobserve(elem);
          self.attachReal(elem);
        });
      };

      var options = {
        root: null,
        rootMargin: '0px',
        threshold: [0.1],
      };

      self.observer = new IntersectionObserver(handler, options);
    },
    attachReal: function(target) {
      var $this = $(target);

      if ($this.hasClass('selectBox-attached')) {
        return;
      }

      var options = {
        mobile: $this.hasClass('selectBox--yes-even-for-mobile'),
        match_select_width: !!$this.data('match-select-width'),
      };
      $this.addClass('selectBox-attached').selectBox(options);
    },
    attach: function(context) {
      var self = this;
      var $selects = $('.selectBox:not(.selectBox-attached)', context);

      this.setupObserver();

      $selects.each(function(i, obj) {
        self.observer.observe(obj);
      });

      $(window).on('resize', _.debounce(function() {
        var $selects = $('.selectBox.selectBox-attached', context);
        self.refresh($selects);
      }, 50));
    },

    // Use this instead of direclty invoking $('.selectBox').selectBox('refresh').
    // The plugin doesn't take into account the fact that the selectBox may be
    // disabled for touch and will blow up if it is.
    refresh: function($selects) {
      $selects.each(function() {
        var control = $(this).data('selectBox-control');
        if (control && control.length) {
          $(this).selectBox('refresh');
        }
      });
    }
  };
})(jQuery);
