/*
 * MantleMorph. Tooling to use some version of dom diffing to apply changes.
 * This is opposed to replacing content wholesale with html().
 *
 * NOTE: This is not shadowdom. It applies to actual dom elements.
 */
var MantleMorph = (function(MantleMorph, morphdom) {
  var defaultOptions = {
    onBeforeElUpdated: function(fromEl, toEl) {
      // eslint-disable-next-line
      //console.log('onBeforeElUpdated', fromEl, toEl);
      return true;
    },
  };

  /*
   * Wrapper around 3rd party dom differ.
   *
   * Currently morphdom.
   */
  MantleMorph.morph = function(fromEl, toEl, options) {
    options = options || {};
    options = Object.assign({}, defaultOptions, options);
    return morphdom(fromEl, toEl, options);
  };

  /*
   * Morph but leaving the root element alone.
   */
  MantleMorph.morphChildren = function(fromEl, toEl, options) {
    options = options || {};
    options = Object.assign({childrenOnly: true}, options);

    return MantleMorph.morph(fromEl, toEl, options);
  };

  return MantleMorph;
})(MantleMorph || {}, window.morphdom);
