/*
 * The reason this js exists is to fix Bounce Exchange code that is adding
 * commas to a tracking cookie. This should be a *temp* fix as either the BX or
 * PG cookie handling should be fixed.
 */
var JSBoot = JSBoot || {};

(function() {
  var ibxSource = JSBoot.URL.getParam('ibx_source');
  var ibxCookie = JSBoot.cookie.get('__ibxc');

  // Cookie utils that replicate BX handling of cookieDomain.
  var href = encodeURIComponent(window.location.href);
  var bounceWebsite = 'toofaced.com';
  var cookieDomain = false;
  // only set explicit cookie domain if we're on toofaced.com
  if (href.indexOf(bounceWebsite) >= 0) {
    cookieDomain = bounceWebsite;
  }

  var cookieSet = function(cookieKey, cookieValue, days) {
    if (!days) {
      days = 365;
    }

    var i = new Date();
    i.setTime(i.getTime() + 24 * days * 60 * 60 * 1e3);
    var e = '; expires=' + i.toGMTString();
    var d = cookieDomain ? 'domain=.' + cookieDomain + ';' : '';
    document.cookie = cookieKey + '=' + cookieValue + e + '; path=/;' + d;
  };

  var cookieRemove = function(cookieKey) {
    cookieSet(cookieKey, '', -1);
  };

  // No existing cookie. Nothign to do.
  if (!ibxCookie) {
    return;
  }

  // If we have ibx_source query param, then the bx js will set the cookie. So
  // we remove the existing cookie to make sure the bx js doesn't append the
  // comma delimited value.
  if (ibxSource) {
    cookieRemove('__ibxc');
    // eslint-disable-next-line no-console
    console.log('Removing cookie', 'ibxSource', ibxSource, 'ibxCookie', ibxCookie);
    return; // since we're removing the cookie. nothing else to do.
  }

  // Now we check if we have a previously set comma limited cookie.
  var cookieParts = ibxCookie.split(',');
  // We don't support commas so only take last value
  if (cookieParts.length > 1) {
    var lastCookie = cookieParts[cookieParts.length - 1];
    cookieSet('__ibxc', lastCookie);
    // eslint-disable-next-line no-console
    console.log('Grabbed last cookie', ibxCookie, lastCookie);
  }
})();
