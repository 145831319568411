var MantleScroll = (function(MantleScroll, $) {

  MantleScroll.offscreen = function($elem) {
    // todo make this vanilla js
    var elementTop = $elem.offset().top;
    var elementBottom = elementTop + $elem.outerHeight(false);
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return (elementBottom < viewportTop || elementTop > viewportBottom);
  };

  return MantleScroll;
})(MantleScroll || {}, jQuery);
