/*
 * Basically the JS version of the php utils.
 *
 * For the most part this should be one-to-one with
 * mantle_forward.fe_images.inc.
 */
var MantleFEImages = (function(MantleFEImages) {
  var MantleImageDeriverAkamaiImageManager = function(mode, imageHost) {
    this.mode = mode;
    this.imageHost = imageHost;
  };

  /*
   * Almost one-to-one copy of the php version. Same class and method name.
   */
  MantleImageDeriverAkamaiImageManager.prototype.deriveImageUrl = function(imagePath, options) {
    var width = options.width;

    var derivatePath = imagePath;
    if (width) {
      derivatePath = derivatePath + '?imwidth=' + width;
    }

    if (this.mode === 'prod') {
      return derivatePath;
    }

    if (this.mode === 'surrogate' && !!this.imageHost) {
      return this.imageHost + derivatePath;
    }
    return imagePath;
  };

  MantleFEImages.MantleImageDeriverAkamaiImageManager = MantleImageDeriverAkamaiImageManager;

  var mantleImageDerivativeConf = window.MantleImageDeriverConf || {};
  var mode = mantleImageDerivativeConf.mode || 'off';
  var imageHost = mantleImageDerivativeConf.image_host;

  MantleFEImages.defaultDeriver = new MantleImageDeriverAkamaiImageManager(mode, imageHost);

  /*
   * Main driver function for generating IM compatible paths.
   */
  MantleFEImages.processImageWidth = function(imagePath, width) {
    var defaultDeriver = this.defaultDeriver;

    var options = {
      width: width,
    };

    var imageUrl = defaultDeriver.deriveImageUrl(imagePath, options);
    return imageUrl;
  };

  return MantleFEImages;
})(MantleFEImages || {});
