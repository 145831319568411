var JSBoot = JSBoot || {};

/*
 * performance.now poly fill.
*/
window.performance = window.performance || {
  offset: Date.now(),
  now: function now() {
    return Date.now() - this.offset;
  }
};

var MantlePerf = (function(MantlePerf) {
  MantlePerf.isDebug = !!JSBoot.cookie.get('perf-debug');

  MantlePerf.accumulatedTimings = {};
  MantlePerf.accumulatedStack = {};

  MantlePerf.accumulateStart = function(key) {
    var t0 = performance.now();
    if (!(key in MantlePerf.accumulatedStack)) {
      MantlePerf.accumulatedStack[key] = [];
    }
    MantlePerf.accumulatedStack[key].push(t0);
  };

  MantlePerf.accumulateEnd = function(key) {
    var t0 = MantlePerf.accumulatedStack[key].pop();
    var t1 = performance.now();
    // Only accumlate for the top level calls.
    if (MantlePerf.accumulatedStack.length > 0) {
      return;
    }
    var diff = t1 - t0;
    if (!(key in MantlePerf.accumulatedTimings)) {
      MantlePerf.accumulatedTimings[key] = 0;
    }
    MantlePerf.accumulatedTimings[key] += diff;
  };

  /*
   * Wraps a param-less function and immediately execute it.
   *
   * This is a more convenient form for anonymous functions.
   */
  MantlePerf.thresholdLog = function(func, key, threshold) {
    var _wrapped = MantlePerf.thresholdLogWrap(func, key, threshold);
    return _wrapped();
  };

  /*
   * Wrap a function so that it logs function execution time.
   *
   * This form returns a wrapped function which can be called later. This is
   * useful for wrapping callbacks that are executed later.
   *
   * Use thresholdLog for param-less immediate execution.
   */
  MantlePerf.thresholdLogWrap = function(func, key, threshold) {
    threshold = threshold || 10;

    if (!key) {
      key = func.name;
    }

    var _wrapped = function() {
      var t0 = performance.now();
      var result = func.apply(null, arguments);
      var t1 = performance.now();
      var diff = t1 - t0;
      if (diff > threshold) {
        if (MantlePerf.isDebug) {
          /* eslint-disable no-console */
          console.log(key + ' took ' + (t1 - t0) + ' milliseconds.');
          /* eslint-enable no-console */
        }
      }
      return result;
    };

    return _wrapped;
  };

  /*
   * Version of Drupal.attachBehaviors that logs performance of attach.
   */
  MantlePerf.attachBehaviors = function(context, settings) {
    context = context || document;
    settings = settings || Drupal.settings;

    var total0 = performance.now();

    $.each(Drupal.behaviors, function(name) {
      var t0 = performance.now();

      if (typeof this.attach === 'function') {
        this.attach(context, settings);
      }

      var t1 = performance.now();
      var diff = t1 - t0;
      // more than 10 ms
      if (diff > 10) {
        /* eslint-disable-next-line no-console */
        console.log(name + '.attach ' + (t1 - t0) + ' milliseconds.');
      }
    });

    var total1 = performance.now();
    /* eslint-disable-next-line no-console */
    console.log('Total attach ' + (total1 - total0) + ' milliseconds.');
  };

  // If debug cookie is on. Take over Drupal.attachBehaviors
  if (MantlePerf.isDebug) {
    Drupal.attachBehaviors = MantlePerf.attachBehaviors;
  }

  return MantlePerf;
})(MantlePerf || {});
