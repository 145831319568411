/* globals MantleReviews */
var generic = generic || {};
var JSBoot = JSBoot || {};
var PowerReviewsAPI = PowerReviewsAPI || {};
var MantleReviewPRConf = MantleReviewPRConf || {};

(function() {
  // for now we just assume first json-ld is the platform one.
  var findProductLD = function() {
    var jsonLDScriptss = document.querySelectorAll('script[type="application/ld+json"]');
    for (var i = 0; i < jsonLDScriptss.length; i++) {
      var jsonLDScript = jsonLDScriptss[i];
      return jsonLDScript;
    }
  };

  /*
   * Parse the /product/cat_id/product_id url.
   */
  var getProductBaseIDFromURL = function() {
    var href = window.location.href;
    var urlParts = JSBoot.URL.urlToParts(href);
    // path without any leading slashes
    var pathname = urlParts.pathname.replace(/^\/+/g, '');
    var pathParts = pathname.split('/');
    if (pathParts.length < 3) {
      return;
    }
    if (pathParts[0] !== 'product') {
      return;
    }
    var categoryId = pathParts[1];
    var productBaseId = pathParts[2];
    if (isNaN(categoryId) || isNaN(productBaseId)) {
      return;
    }
    return productBaseId;
  };

  /*
   * Overall process to request snippet data and attach to product json-ld
   *
   * It will attach the AggreateRating structured data attribute.
   */
  var attachedAggreatePowerReviews = function() {
    var productBaseId = getProductBaseIDFromURL();
    var productLDScript = findProductLD();

    if (!productLDScript || !productBaseId) {
      return;
    }

    var content = productLDScript.textContent;
    var structuredData = JSON.parse(content);

    if (structuredData['@type'] !== 'Product') {
      return;
    }

    var api = PowerReviewsAPI.getPowerReviewsApiFromSettings !== undefined ? PowerReviewsAPI.getPowerReviewsApiFromSettings(MantleReviewPRConf) : null;

    // Bail if Power Reviews not set up. api is null if there are no valid
    // settings.
    if (!api) {
      return;
    }

    var subject = api.getSnippet(productBaseId);
    subject.subscribe(function(rollup) {
      // eslint-disable-next-line no-console
      console.log('PRReviews Rollup', rollup);

      structuredData.aggregateRating = {
        '@type': 'AggregateRating',
        'ratingValue': rollup.average_rating,
        'reviewCount': rollup.review_count,
      };

      productLDScript.textContent = JSON.stringify(structuredData);
    });
  };

  attachedAggreatePowerReviews();
})();
