var MantlePerf = MantlePerf || {};
var MantlePD = MantlePD || {};
var JSBoot = JSBoot || {};
var generic = generic || {};
var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};

var site = site || {};
site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

(function($) {
  // No idea why this was initialized in the attach func.
  if (!prodcat.data.store) {
    prodcat.data.store = {
      categories: {},
      products: {},
      skus: {}
    };
  }

  prodcat.data.collectProductIds = function($context) {
    var prodIds = [];
    $('[data-product-id]', $context).each(function() {
      var $this = $(this);
      var prodId = $this.attr('data-product-id');
      // Check if a prodId exists
      if (!prodId) {
        return null;
      }
      prodId = String(prodId);
      // Multi Product Ids / Add All to Cart
      if (prodId.indexOf(',') >= 0) {
        prodId = prodId.split(',');
        if (prodId.indexOf(',') === -1) {
          prodId.pop();
        }
        var insert = true;
        $.each(prodId, function(intIndex, objValue) {
          for (var i = prodIds.length - 1; i > -1; i--) {
            if (prodIds[i] === objValue) {
              insert = false;
              break;
            }
          }
          if (insert) {
            prodIds.push(objValue);
          }
          insert = true;
        });
      }
      else {
        var insert = true;
        for (var i = prodIds.length - 1; i > -1; i--) {
          if (prodIds[i] === prodId) {
            insert = false;
            break;
          }
        }
        if (insert) {
          prodIds.push(prodId);
        }
        insert = true;
      }
    });
    return prodIds;
  };

  prodcat.data.collectSkuIds = function($context) {
    var skuIds = [];
    $('.js-product[data-sku-base-id]', $context).each(function() {
      var $this = $(this);
      var skuId = $this.attr('data-sku-base-id');
      // Check if a prodId exists
      if (!skuId) {
        return null;
      }
      var insert = true;
      for (var i = skuIds.length - 1; i > -1; i--) {
        if (skuIds[i] === skuId) {
          insert = false;
          break;
        }
      }
      if (insert) {
        skuIds.push(skuId);
      }
      insert = true;
    });
    return skuIds;
  };

  /*
   * Retrieves product data from data store. Folds full sku data into product.skus array
   * @param {String} prodId
   * @param {array} skuIds
   */
  prodcat.data.getProduct = function(prodId, skuIds) {
    if (!prodcat.data.store.products[prodId]) {
      /* eslint-disable-next-line no-console */
      console.warn('Tried to getProduct for ' + prodId + ' but no data exists');
      return null;
    }

    // Note this is a shallow copy
    var prodData = _.extend({}, prodcat.data.store.products[prodId]);
    if (!skuIds) {
      skuIds = prodData.skus;
    }

    // Since we're not doing a deep copy. reset the 'skus' so we don't clobber
    // the original skus array.
    // Whoever decided to repurpose the sku array in product data. Well. Yeah.
    prodData.skus = [];

    _.each(skuIds, function(skuId, idx) {
      prodData.skus[idx] = prodcat.data.getSku(skuId);
    });
    return prodData;
  };

  /*
   * Get productData from $product el. This will respect pd-manifest if it is
   * there.
   */
  prodcat.data.getProductFromEl = function($product, options) {
    return MantlePD.getProductFromEl($product, options);
  };

  prodcat.data.getSku = function(skuId) {
    skuId = skuId + ''; // Has to be a string to run indexOf
    skuId = skuId.indexOf('SKU') === 0 ? skuId : 'SKU' + skuId;
    var skuData = prodcat.data.store.skus[skuId];
    if (!skuData) {
      /* eslint-disable-next-line no-console */
      console.warn('Tried to getSku for ' + skuId + ' but no data exists');
      return null;
    }
    return skuData;
  };

  /*
   * Sanitize the product data to ensure a consistent data structure
   */
  prodcat.data.sanitizeProductData = function(product) {
    // Force the following fields to be an array even if they're originally a string
    var pf = ['IMAGE_L', 'IMAGE_M', 'IMAGE_S', 'IMAGE_XL', 'IMAGE_XM', 'IMAGE_XXL'],
        fk = '';
    for (var i = pf.length; i--;) {
      fk = pf[i];
      if (_.isUndefined(product[fk])) {
        continue;
      }
      product[fk] = typeof product[fk] === 'string' ? [ product[fk] ] : product[fk];
    }

    return product;
  };

  /*
   * L2_DANCE_PARTY
   *
   * Basically a debug tool that will randomly assign inv status to l2 calls if
   * the L2_DANCE_PARTY cookie is set.
   */
  prodcat.data.L2DanceParty = function(productsArray) {
    if (_.isUndefined(JSBoot)) {
      return;
    }
    if (!JSBoot.cookie.get('L2_DANCE_PARTY')) {
      return;
    }

    var _statuses = [1, 2, 3, 4, 5, 7];
    $.each(productsArray, function(i, product) {
      if (_.isEmpty(product)) {
        return;
      }
      _.forEach(product.skus, function(sku) {
        var fake_status = _statuses[_.random(_statuses.length - 1)];
        sku.INVENTORY_STATUS = fake_status;
        sku.PRICE2 = '30';
        sku.formattedPrice2 = '$' + sku.PRICE2;
      });
    });
  };

  prodcat.data.product_alters = {};

  prodcat.data.addProductAlter = function(ns, callback) {
    prodcat.data.product_alters[ns] = callback;
  };

  /*
   * Allow the ability to do FE prodcat data alters.
   */
  prodcat.data.applyProductAlters = function(prod) {
    for (var ns in prodcat.data.product_alters) {
      if (!prodcat.data.product_alters[ns]) {
        continue;
      }
      var callback = prodcat.data.product_alters[ns];
      if (typeof callback !== 'function') {
        continue;
      }
      callback(prod);
    }
  };

  prodcat.data.updateProducts = function(productsArray, deepCopy) {
    var self = this;
    deepCopy = deepCopy === false ? deepCopy : true; // do a deep copy of the product data by default
    /* eslint-disable-next-line complexity */
    _.each(productsArray, function(newProd) {
      if (!newProd) {
        return;
      }
      var targetProd = $.extend(deepCopy, {}, newProd);
      var oldProd = prodcat.data.store.products[newProd.PRODUCT_ID];

      // this is largely for dev. disable l2 updates so that we can test inv
      // statuses.
      if (oldProd && oldProd.disable_l2_update) {
        return;
      }

      if (_.isArray(newProd.skus)) {
        prodcat.data.updateSkus(targetProd.skus);
        targetProd.skus = _.map(targetProd.skus, function(sku) {
          return sku.SKU_ID;
        });

        // Make sure L2, different categories for same product, or SKU based
        // IDs don't reset the sku list. The skus array from prodcat.data
        // should be all encompassing. Be treat more like a data store.
        if (oldProd && _.isArray(oldProd.skus)) {
          targetProd.skus = _.union(oldProd.skus, targetProd.skus);
        }
      }

      var prod = prodcat.data.sanitizeProductData(_.isObject(oldProd) ? _.extend(oldProd, targetProd) : targetProd);

      // Set defaultSku to the first sku if not already set:
      if (_.isUndefined(prod.defaultSku) && prod.skus && prod.skus.length) {
        prod.defaultSku = self.getSku(prod.skus[0]);
      }

      prodcat.data.applyProductAlters(prod);

      prodcat.data.store.products[targetProd.PRODUCT_ID] = prod;
    });

    $(document).trigger('prodcat:products:updated', prodcat.data.store.products);
  };

  /*
   * Sanitize the sku data to ensure a consistent data structure
   */
  prodcat.data.sanitizeSkuData = function(sku) {
    // Remove any "product" keys from the sku object to prevent recursion errors down the road.
    sku.product = undefined;

    // Force the following fields to be an array even if they're originally a string
    var sf = ['IMAGE_SMOOSH_L', 'IMAGE_SMOOSH_S', 'IMAGE_SMOOSH_XL'],
        fk = '';
    for (var i = sf.length; i--;) {
      fk = sf[i];
      if (_.isUndefined(sku[fk])) {
        continue;
      }
      sku[fk] = typeof sku[fk] === 'string' ? [ sku[fk] ] : sku[fk];
    }

    return sku;
  };

  prodcat.data.updateSkus = function(skusArray) {
    _.each(skusArray, function(newSku) {
      newSku = prodcat.data.sanitizeSkuData(newSku);
      var oldSku = prodcat.data.store.skus[newSku.SKU_ID];
      prodcat.data.store.skus[newSku.SKU_ID] = _.isObject(oldSku) ? _.extend(oldSku, newSku) : newSku;
    });
  };

  /*
   * Update prodcat.data.store from page_data (L1)
   */
  /* eslint-disable-next-line complexity */
  prodcat.data.updatePageData = function(page_data) {
    function _catStore(newCat) {
      var oldCat = prodcat.data.store.categories[newCat.CATEGORY_ID];
      var targetCat = $.extend(true, {}, newCat);
      if (_.isArray(targetCat.products)) {
        prodcat.data.updateProducts(targetCat.products);
      }
      targetCat.products = _.map(targetCat.products, function(prod) {
        return prod.PRODUCT_ID;
      });
      prodcat.data.store.categories[targetCat.CATEGORY_ID] = _.isObject(oldCat) ? _.extend(oldCat, targetCat) : targetCat;
    }

    for (var key in page_data) {
      if (!page_data[key]) {
        continue;
      }
      if (page_data[key].categories && _.isArray(page_data[key].categories)) {
        _.each(page_data[key].categories, _catStore);
      }
      if (page_data[key].products && _.isArray(page_data[key].products)) {
        prodcat.data.updateProducts(page_data[key].products);
      }
      if (page_data[key].product && _.isObject(page_data[key].product)) {
        prodcat.data.updateProducts([page_data[key].product]);
      }
    }
  };

  /*
   * Generate the request params used for L2 calls. Also handles callback.
   */
  prodcat.data.generateL2Request = function(pids) {
    var onSuccess = function(r) {
      if (
        !r ||
        !r.result ||
        !r.result.value ||
        !r.result.value.products
      ) {
        return;
      }

      var prods = _.compact(r.result.value.products);

      prodcat.data.L2DanceParty(prods);

      prodcat.data.updateProducts(prods);

      _.each(prods, function(prod) {
        var prodSlctr = "[data-product-id='" + prod.PRODUCT_ID + "']";
        var $products = $(prodSlctr);
        $products.trigger('inv_status_data:updated');
      });

      if (prods.length > 0) { // can't think of a reason why this WOULD be empty, but check, just in case
        // trigger a custom event, letting all who care know that we've updated inventory status data for every product
        $(document).trigger('inv_status_data:finished');
      }

      $(document).trigger('prodcat.data.query.success');
    };

    var request = {
      method: 'prodcat',
      params: [{
        products: pids,
        product_fields: ['PRODUCT_ID', 'skus'],
        sku_fields: ['SKU_ID', 'INVENTORY_STATUS', 'isShoppable', 'isComingSoon', 'isOrderable'],
      }],
      onSuccess: MantlePerf.thresholdLogWrap(onSuccess, 'productdata.L2.onSuccess'),
    };

    return request;
  };

  /*
   * Queue L2 onload call
   */
  prodcat.data.initialL2Call = function() {
    var pids = prodcat.data.collectProductIds();
    if (pids.length === 0) {
      return;
    }

    var L2ProductRequest = prodcat.data.generateL2Request(pids);
    // prod.data.pids is used by analytics.
    prodcat.data.pids = pids;
    site.onLoadRpc.requests.push(L2ProductRequest);
  };

  /*
   * Useful for getting L2 for ajaxed in content.
   */
  prodcat.data.getNewProducts = function($context) {
    var pids = prodcat.data.collectProductIds($context);
    if (pids.length === 0) {
      return;
    }
    var L2ProductRequest = prodcat.data.generateL2Request(pids);

    generic.jsonrpc.fetch(L2ProductRequest);
  };

  prodcat.data.isPaletteMultiSku = function(prodId) {
    var prodData = prodcat.data.getProduct(prodId);
    return !!prodData.isPaletteMultiSku;
  };

  /*
   * Called from Drupal.behaviors.prodcatDataInit.attach
  */
  prodcat.data.init = function() {
    if (typeof page_data === 'undefined' || !page_data) {
      return null;
    }

    prodcat.data.updatePageData(page_data);
  };

  /**
   * Pull data from page_data and store internally.
   */
  Drupal.behaviors.prodcatDataInit = {
    attached: false,
    attach: function() {
      if (!this.attached) {
        prodcat.data.init();
      }
      this.attached = true;
    },
  };
})(jQuery);
