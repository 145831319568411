/* eslint-disable-next-line no-unused-vars */
var MantleBehavior = {
  /*
   * A behavior base object that enables easy lazy loading.
   *
   * TODO: Merge this into a sidecar and integrate into a future animation
   * frameowrk so that we can dictate both lazyloading + load animations.
   * Right now we hope to trigger enough below screen that block is loaded
   * before we reach.
   */
  create: function(behavior) {
    return Object.assign({}, this, behavior);
  },
  _observer: null,
  observerOptions: {
    root: null,
    rootMargin: '0px 0px 20% 0px',
    threshold: [0.1],
  },
  // WeakMap to keep track of handled elements. It's possible to trigger an
  // Intersection event multiple times for the same element when we only wnat
  // to lazyload once.
  handledElements: new WeakMap(),
  setupObserver: function() {
    var self = this;

    if (self._observer !== null) {
      return;
    }
    var handler = function(entries) {
      _.each(entries, function(entry) {
        if (!entry.isIntersecting) {
          return;
        }

        var elem = entry.target;
        self._observer.unobserve(elem);
        if (self.handledElements.get(elem)) {
          // Already handled this element
          return;
        }

        self.handledElements.set(elem, true);
        self.attachLazyTarget(elem);
      });
    };

    self._observer = new IntersectionObserver(handler, this.observerOptions);
  },

  attach: function(context) {
    var mod = this;

    if (mod.attachLazyTarget) {
      mod.setupObserver();
    }

    var $targets = this._gatherTargetsSystem(context);

    if (!$targets || !$targets.length) {
      return;
    }

    $targets.each(function(i, target) {
      mod._processTarget(target);
    });
  },

  /*
   * Allow external modules to run through this behaviors process loop without
   * being added during the attach / gatherTargets phase.
   */
  forceProcessTarget: function(target) {
    return this._processTarget(target);
  },

  /*
   * Main func that processes the targets. For now this will execute a
   * 1. sync attachTarget
   * 2. lazyloaded attachLazyTarget.
   *
   */
  _processTarget: function(target) {
    // attachTarget will still run if it exists.
    if (this.attachTarget) {
      this.attachTarget(target);
    }

    // Lazy load if needed. note self.observer is null if attachLazyTarget
    // does not exist.
    if (this._observer) {
      this._observer.observe(target);
    }
  },

  /*
   * Allow override the gatherTargets or use the default target selector.
   */
  _gatherTargetsSystem: function(context) {
    if (!this.targetSelector && !this.gatherTargets) {
      /* eslint-disable no-console */
      console.warn('No target selector or gatherTargets method');
      return;
    }

    if (this.gatherTargets) {
      return this.gatherTargets(context);
    }

    var $targets = $(this.targetSelector, context);
    return $targets;
  }
};
