var generic = generic || {};
var JSBoot = JSBoot || {};

/*
 * Handles the calls and data management for using the Power Reviews Read Api.
 * https://developers.powerreviews.com/Content/reference/read.html
 *
 * It makes use of BehaviorSubjects so that data is not overfetched while still
 * supporting callbacks.
 */
var PowerReviewsAPI = function(options) {
  options = options || {};
  this.base_url = options.api_url || 'https://readservices-b2c.powerreviews.com';
  this.api_key = options.api_key;
  this.locale = options.locale;
  this.merchant_id = options.merchant_id;
  this.snippetSubjects = new JSBoot.SubjectHash();

  // Holds the configuration data that gets returned with every PR request.
  // This includes settings and translations.
  this.configurationSubject = new JSBoot.BehaviorSubject();
};

/*
 * Calls PowerReviews Read API for snippets.
 *
 * This should not be called directly. Use getSnippets or getSnippet
 */
PowerReviewsAPI.prototype.getSnippetsXHR = function(pageIds, successCallback) {
  // Convert pageIDs to an array.
  if (!Array.isArray(pageIds)) {
    pageIds = String(pageIds);
    pageIds = pageIds.split(',').map(function(item) {
      return item.trim();
    });
  }

  var pageIdsString = pageIds.join(',');
  var api_url = this.base_url + '/m/' + this.merchant_id + '/l/' + this.locale + '/product/' + pageIdsString + '/snippet?apikey=' + this.api_key;
  var options = {
    success: function(data, responseText, req) {
      // this should always return json.
      var prJson = JSON.parse(data);
      if (!prJson || !prJson.configuration) {
        return;
      }
      // Attach the request pageIds
      prJson.pageIds = pageIds;
      if (successCallback) {
        successCallback(prJson);
      }
    },
  };

  return JSBoot.ajax.request(api_url, options);
};

/*
 * Process the json from power reviews and update the subjects.
 */
// eslint-disable-next-line complexity
PowerReviewsAPI.prototype.processSnippetJson = function(prJson) {
  // Sometimes a pageId will return an empty result. We need to treat this as
  // the same as getting a {review_count: 0} resut to replicate the base
  // PowerReviews FE behavior.
  if (!prJson || (!prJson.results && !prJson.pageIds)) {
    return;
  }

  var results = prJson.results;
  var configurationSubject = this.configurationSubject;
  var configuration = prJson.configuration;

  configurationSubject.next(configuration);

  var handledPageIds = {};

  for (var i = 0; i < results.length; i++) {
    var result = results[i];
    var pageId = result.page_id;
    var rollup = result.rollup;
    if (!result.rollup) {
      continue;
    }
    if (handledPageIds[pageId]) {
      continue;
    }
    var subject = this.snippetSubjects.get(pageId);
    rollup.page_id = pageId;
    subject.next(rollup);

    handledPageIds[pageId] = true;
  }

  // Handle cases where we are missing the results. We have to simualate
  // getting back a 0 review_count
  if (prJson.pageIds && Array.isArray(prJson.pageIds)) {
    this.processMissingResults(prJson.pageIds, handledPageIds);
  }
};

/*
 * Replicate the 0 review result that is sometimes missing.
 *
 * This seemed to have been a change with PR API. Sometimes a product will
 * return no results or it will return an empty result. The Power Reviews FE
 * treats this the same. It will render the 0 reviews/Write the first review
 * blurb.
 */
PowerReviewsAPI.prototype.processMissingResults = function(pageIds, handledPageIds) {
  for (var i = 0; i < pageIds.length; i++) {
    var pageId = pageIds[i];

    if (handledPageIds[pageId]) {
      continue;
    }

    var fauxRollup = {
      review_count: 0,
      answered_questions: 0,
      page_id: pageId,
    };
    var subject = this.snippetSubjects.get(pageId);
    subject.next(fauxRollup);

    handledPageIds[pageId] = true;
  }
};

/*
 * Subject based getConfiguration.
 *
 * PowerReviews sends back a configuration object with every request. This
 * subject will have new values sent multiple times. This is likely worth
 * tracking and only sending once. But for now, downstream subscribers should
 * be aware.
 */
PowerReviewsAPI.prototype.getConfiguration = function() {
  return this.configurationSubject;
};

/*
 * Subject based getSnippets.
 *
 * Note that we will return BehaviorSubjects for all pageIds even if we do not
 * make an XHR request because we already have the data.
 */
PowerReviewsAPI.prototype.getSnippets = function(pageIds) {
  var self = this;
  var subjects = {};
  var newPageIds = [];

  for (var i = 0; i < pageIds.length; i++) {
    var pageId = pageIds[i];
    var exists = this.snippetSubjects.contains(pageId);
    var subject = this.snippetSubjects.get(pageId);
    subjects[pageId] = subject;
    if (!exists) {
      newPageIds.push(pageId);
    }
  }

  if (newPageIds.length > 0) {
    var callback = function(prJson) {
      self.processSnippetJson(prJson);
    };
    this.getSnippetsXHR(newPageIds, callback);
  }

  return subjects;
};

/*
 * Subject based getSnippet call.
 */
PowerReviewsAPI.prototype.getSnippet = function(pageId) {
  var self = this;
  var exists = this.snippetSubjects.contains(pageId);
  var subject = this.snippetSubjects.get(pageId);

  // For now we assume that if the subject is already in the hash. We've
  // already made the xhr call.
  if (!exists) {
    var callback = function(prJson) {
      self.processSnippetJson(prJson);
    };
    this.getSnippetsXHR(pageId, callback);
  }
  return subject;
};

/*
 * TODO: no idea if this is a good format. This is just what rollout has been
 * doing for configs.
 *
 * powerReviewsSettings is Drupal.settings.power_reviews or something similar.
 *
 * see: MantleReviews.get_power_reviews_configuration
 */
// eslint-disable-next-line complexity
PowerReviewsAPI.getPowerReviewsConfigFromSettings = function(powerReviewsSettings) {
  var pr_configuration = powerReviewsSettings.merchant_ids;

  // Assume that without merchant_ids that the setting itself is the correct
  // setting.
  if (!pr_configuration && powerReviewsSettings && powerReviewsSettings.merchant_id) {
    return powerReviewsSettings;
  }

  if (!pr_configuration) {
    return null;
  }

  var locale = null;
  locale = JSBoot.cookie.get('LOCALE') || 'en_US';
  locale = locale.toLowerCase();

  if (!(locale in pr_configuration)) {
    return;
  }

  var locale_conf = null;
  $.each(pr_configuration[locale], function(locale, merchant_id) {
    locale_conf = {'locale': locale, 'merchant_id': merchant_id};
  });
  return locale_conf;
};

/*
 * The assumption is the prApi derived from Drupal doesn't change within a
 * request. So we staticly cache.
 */
PowerReviewsAPI.getPowerReviewsApiFromSettings = function(powerReviewsSettings) {
  if (typeof PowerReviewsAPI.prApi === 'undefined') {
    var prApi = PowerReviewsAPI._getPowerReviewsApiFromSettings(powerReviewsSettings);
    PowerReviewsAPI.prApi = prApi;
  }
  return PowerReviewsAPI.prApi;
};

/*
 * Generate a PowerReviewsAPI object from the Drupal configs.
 */
PowerReviewsAPI._getPowerReviewsApiFromSettings = function(powerReviewsSettings) {
  var locale_merchant = PowerReviewsAPI.getPowerReviewsConfigFromSettings(powerReviewsSettings);
  if (!locale_merchant) {
    return;
  }

  var merchant_id = locale_merchant.merchant_id;
  var locale = locale_merchant.locale;

  var base_url = powerReviewsSettings.api_url || 'https://readservices-b2c.powerreviews.com';
  var api_key = powerReviewsSettings.api_key;
  var options = {
    merchant_id: merchant_id,
    locale: locale,
    base_url: base_url,
    api_key: api_key,
  };

  var prApi = new PowerReviewsAPI(options);
  return prApi;
};
