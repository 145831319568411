/*
 * MantlePD JS API.
 *
 * Most of the functions in this file mirror php functions. The idea is to have
 * the same data alter pipeline work for both PHP/JS via the same configs. This
 * is to gain some level of faux iso-morphism.
 *
 * NOTE: That the method names are purpose *not* camelCase so that they match
 * exactly with the php API.
 */
var prodcat = prodcat || {};

var MantlePD = (function(MantlePD, Drupal) {
  /*
   * A copy of the php mantle_common_nested_key_get function.
   * Used to access a nested object via a single structured key.
   */
  // eslint-disable-next-line complexity
  var mantleCommonNestedKeyGet = function(data, key, delimiter, silent) {
    delimiter = typeof delimiter !== 'undefined' ? delimiter : '.';
    silent = typeof silent !== 'undefined' ? silent : true;

    var keyParts = key.split(delimiter);
    var currentObj = data;
    for (var i = 0; i < keyParts.length; i++) {
      var currentKey = keyParts[i];
      // match php isset.
      if (typeof currentObj[currentKey] === 'undefined' || currentObj[currentKey] === null) {
        if (!silent) {
          throw new Error('Nested key ' + key + ' not found on object');
        }
        return null;
      }
      currentObj = currentObj[currentKey];
    }
    return currentObj;
  };

  // http://locutus.io/php/var/empty/
  var php_empty = function(mixedVar) {
    var undef;
    var key;
    var i;
    var len;
    var emptyValues = [undef, null, false, 0, '', '0'];

    for (i = 0, len = emptyValues.length; i < len; i++) {
      if (mixedVar === emptyValues[i]) {
        return true;
      }
    }

    if (typeof mixedVar === 'object') {
      for (key in mixedVar) {
        if (mixedVar.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }

    return false;
  };

  /*
   * Consensus Field API
   *
   * This mirrors the functions in mantle_pd.consensus_fields.inc
   */
  MantlePD.get_consensus_config = function() {
    if (Drupal && Drupal.settings && Drupal.settings.consensus_config) {
      return Drupal.settings.consensus_config;
    }
    return null;
  };

  /*
   * Given a consensus field config, generate all the relevant working set data.
   */
  MantlePD.derive_all_fields = function(data, consensus_config) {
    var working_set = {};

    for (var cf_key in consensus_config) {
      var cf_info = consensus_config[cf_key];
      var working_value = this.derive_single_field(data, cf_key, cf_info);
      if (!_.isNull(working_value)) {
        working_set[cf_key] = working_value;
      }
    }

    return working_set;
  };

  /*
   * Process a single field conf.
   */
  MantlePD.derive_single_field = function(data, cf_key, cf_info) {
    var working_value = null;

    if (cf_info.type === 'default') {
      working_value = data[cf_key];
    }

    if (cf_info.type === 'derived') {
      working_value = MantlePD.get_derived_field_value(cf_info, data);
    }
    return working_value;
  };

  /* eslint-disable-next-line complexity */
  MantlePD.get_derived_field_value_translate = function(value, translate_info) {
    var field_values = value;
    var i;
    var t_key;
    var t_value;
    var isEmpty = true;
    var isNull = true;

    if (!Array.isArray(field_values)) {
      field_values = [field_values];
    }

    for (i = 0; i < field_values.length; i++) {
      var field_value = field_values[i];

      // Due to json limitation. We need to compare strings.
      if (!!field_value) {
        field_value = field_value.toString();
      }

      // Check the straight string matches.
      for (t_key in translate_info) {
        t_value = translate_info[t_key];

        // Normalize t_key to array form.
        if (!Array.isArray(t_key)) {
          t_key = [t_key];
        }

        // for grouping multiple values into one bucket
        if (_.includes(t_key, field_value)) {
          return t_value;
        }
      }

      // Keep track of field values for the special # tags.
      if (field_value === null) {
        isNull = false;
      }

      if (!php_empty(field_value)) {
        isEmpty = false;
      }
    }

    for (t_key in translate_info) {
      t_value = translate_info[t_key];

      // Normalize $t_key to array form.
      if (!_.isArray(t_key)) {
        t_key = [t_key];
      }

      // note that #notnull, #notempty checks are done *after* the
      // in_array. So they need to be last in the translate list.
      if (_.includes(t_key, '#notnull') && !isNull) {
        return t_value;
      }

      if (_.includes(t_key, '#notempty') && !isEmpty) {
        return t_value;
      }
    }
    return null;
  };

  MantlePD.get_derived_field_value = function(cf_info, sku) {
    var backing_field = cf_info.backing_field;
    var backing_value = mantleCommonNestedKeyGet(sku, backing_field);
    var translate_info = null;

    if (!_.isEmpty(cf_info.translate)) {
      translate_info = cf_info.translate;
      // This will take a comma list and turn it into a trimed array.
      if (backing_value && cf_info['#preprocess'] === 'split_trim') {
        backing_value = backing_value.toString().split(',').map(function(item) {
          return item.trim();
        });
      }
      backing_value = MantlePD.get_derived_field_value_translate(backing_value, translate_info);
    }

    return backing_value;
  };

  /* eslint-disable-next-line complexity */
  MantlePD.derive_consensus = function(product, skus, consensus_config) {
    if (_.isEmpty(skus)) {
      return {};
    }

    if (_.isNull(consensus_config) || _.isUndefined(consensus_config)) {
      consensus_config = MantlePD.get_consensus_config();
    }

    var consensus_working_set = {};

    for (var i = 0; i < skus.length; i++) {
      var sku = skus[i];
      var working_set = MantlePD.derive_all_fields(sku, consensus_config);
      consensus_working_set[sku.SKU_BASE_ID] = working_set;
    }

    var consensus = {};
    var consensus_fail = {};
    for (var sku_base_id in consensus_working_set) {
      var sku_working_set = consensus_working_set[sku_base_id];
      for (var cf in consensus_config) {
        // already failed. move on
        if (!php_empty(consensus_fail[cf])) {
          continue;
        }

        // Default for null for no value.
        var working_value = null;
        if (cf in sku_working_set) {
          working_value = sku_working_set[cf];
        }

        // first time running into this field
        if (!(cf in consensus)) {
          consensus[cf] = working_value;
          continue;
        }

        // Make sure we are equal
        if (working_value !== consensus[cf]) {
          consensus_fail[cf] = true;
          delete consensus[cf];
        }
      }
    }

    // Don't return NULL
    for (var k in consensus) {
      if (consensus[k] === null) {
        delete consensus[k];
      }
    }

    return consensus;
  };
  /* </ Consensus Field API> */

  /*
   * Get productData from $product el. This will respect pd-manifest if it is
   * there.
   */
  // eslint-disable-next-line complexity
  MantlePD.getProductFromEl = function($product, options) {
    var productID = $product.data('product-id');
    options = options || {};

    // If the product element itself has a sku-base-id. Pass that along to
    // set defaultSku when pdManifest is empty.
    var defaultSkuBaseId = $product.data('sku-base-id');
    if (!!defaultSkuBaseId) {
      options.productElementSkuBaseId = parseInt(defaultSkuBaseId);
    }

    // See if Product Display Manifest exists and try to pass in the
    // display_sku_list
    var pdManifest = $product.data('pd-manifest');
    var productData = MantlePD.getProductDataWithManifest(productID, pdManifest, options);

    return productData;
  };


  /*
   * Get productData as defined by the pdManifest.
   */
  // eslint-disable-next-line complexity
  MantlePD.getProductDataWithManifest = function(productID, pdManifest, options) {
    var skuIds = null;
    if (!!pdManifest && pdManifest.display_sku_list) {
      skuIds = pdManifest.display_sku_list;
    }

    // If we should be using context_skus. Build the product data from that sku
    // list
    if (!!pdManifest && pdManifest.context_sku_list && options.use_context_skus) {
      if (pdManifest.context_sku_list === 'DISPLAY_SKUS') {
        skuIds = pdManifest.display_sku_list;
      } else {
        skuIds = pdManifest.context_sku_list;
      }
    }
    var defaultSkuBaseId = options.productElementSkuBaseId;

    if (!!pdManifest && !!pdManifest.default_sku_base_id) {
      defaultSkuBaseId = pdManifest.default_sku_base_id;
    }


    if (options.forceSingleSku && !!defaultSkuBaseId) {
      skuIds = [defaultSkuBaseId];
    }

    var productData = MantlePD.getPDProductData(productID, skuIds);
    if (!!pdManifest) {
      // make sure sku_as_product is correct if multiple views of same product.
      if (typeof pdManifest.sku_as_product === 'boolean') {
        productData.sku_as_product = pdManifest.sku_as_product;
      }
    }

    if (!!defaultSkuBaseId && productData.skus) {
      // Make sure defaultSkuBaseID is an int.
      defaultSkuBaseId = parseInt(defaultSkuBaseId);
      for (var i = 0; i < productData.skus.length; i++) {
        var sku = productData.skus[i];
        if (sku.SKU_BASE_ID === defaultSkuBaseId) {
          productData.defaultSku = sku;
          break;
        }
      }
    }
    return productData;
  };

  /*
   * Retrieves product data from data store. Folds full sku data into product.skus array
   *
   * Allows defining the skuIds list.
   *
   * Requires standard prodcat.data functions.
   *
   * @param {String} prodId
   * @param {array} skuIds
   *
   */
  MantlePD.getPDProductData = function(prodId, skuIds) {
    if (!prodcat.data.store.products[prodId]) {
      // eslint-disable-next-line no-console
      console.warn('Tried to getProduct for ' + prodId + ' but no data exists');
      return null;
    }

    // Note this is a shallow copy
    var prodData = _.extend({}, prodcat.data.store.products[prodId]);
    if (!skuIds) {
      skuIds = prodData.skus;
    }

    // Since we're not doing a deep copy. reset the 'skus' so we don't clobber
    // the original skus array.
    // Whoever decided to repurpose the sku array in product data. Well. Yeah.
    prodData.skus = [];

    _.each(skuIds, function(skuId, idx) {
      prodData.skus[idx] = prodcat.data.getSku(skuId);
    });
    return prodData;
  };

  MantlePD.getUpdatedManifestFromEl = function($target) {
    if (!$target.data('pd-manifest')) {
      return;
    }

    var pd_manifest = $target.data('pd-manifest');
    var prodData = MantlePD.getProductFromEl($target);
    var updated_consensus = MantlePD.derive_consensus(prodData, prodData.skus);
    pd_manifest.consensus = updated_consensus;
    return pd_manifest;
  };

  return MantlePD;
})(MantlePD || {}, Drupal || {});
